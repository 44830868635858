import { graphql, useStaticQuery } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { filter, isEmpty, map, some } from 'lodash'
import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'

import {
  HERO_CAROUSEL_ANIMATION_DELAY,
  HERO_LOGO_ANIMATION_DELAY,
  HERO_LOGO_ANIMATION_MOBILE_DELAY,
  MOBILE_VIEW_BREAKPOINT,
} from '../../constants'
import useHomepage from '../../hooks/useHomepage'
import ScrollAnimation from '../scroll-animation/Container'
import HeroForm from './HeroForm'
import './hero.less'

type Slide = {
  image: { childImageSharp: { fluid: { src: string } } }
  logo: string
  logo_class: string
}

type Props = {
  isFormRequired: boolean
  phoneForm: boolean
}

const HeroContainer: React.FC<Props> = ({ isFormRequired, phoneForm }) => {
  const { activeCityName, animationProps } = useHomepage()
  const [slides, setSlides] = useState<Slide[]>([])
  const isMobile = typeof window != 'undefined' && window.innerWidth < MOBILE_VIEW_BREAKPOINT
  const language = useI18next().language
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 10000, min: 0 },
      items: 1,
    },
  }

  const data = useStaticQuery(graphql`
    {
      allHeroJson {
        edges {
          node {
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            logo
            logo_class
            city_name
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!isEmpty(activeCityName)) {
      const activeCityHasImages = some(
        data.allHeroJson.edges,
        (edge) => edge.node.city_name === activeCityName.toLowerCase(),
      )
      const city = activeCityHasImages ? activeCityName : 'new york city'
      const slides = map(
        filter(data.allHeroJson.edges, (edge) => edge.node.image && edge.node.city_name === city.toLowerCase()),
        (edge) => edge.node,
      )
      setSlides(slides)
    }
  }, [activeCityName])

  return (
    <div className={`hero ${!slides.length && 'hero--empty'} ${!isFormRequired && 'hero--join'} hero--${language}`}>
      {slides.length > 0 && (
        <div className="hero__image">
          <ScrollAnimation
            {...animationProps}
            animateIn={isMobile ? 'fadeIn' : 'fadeInLeftHero'}
            delay={isMobile ? 0 : HERO_CAROUSEL_ANIMATION_DELAY}
          >
            <Carousel
              responsive={responsive}
              infinite={true}
              showDots={true}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={4000}
              transitionDuration={600}
              dotListClass="hero__carousel-dots"
              itemClass="hero__carousel-item"
            >
              {slides.map((slide: Slide, index: number) => (
                <div key={index}>
                  <div
                    className="hero__carousel-image"
                    style={{
                      background: `url(${slide.image.childImageSharp.fluid.src}) no-repeat`,
                    }}
                  />
                  <div className="hero__carousel-logo-wrapper">
                    <ScrollAnimation
                      {...animationProps}
                      animateIn="fadeInLeftHero"
                      delay={isMobile ? HERO_LOGO_ANIMATION_MOBILE_DELAY : HERO_LOGO_ANIMATION_DELAY}
                    >
                      <div className={`hero__carousel-logo hero__carousel-logo--${slide.logo_class}`}>
                        <img src={slide.logo} />
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              ))}
            </Carousel>
          </ScrollAnimation>
        </div>
      )}
      <HeroForm isFormRequired={isFormRequired} phoneForm={phoneForm} />
    </div>
  )
}

export default HeroContainer
