import { Link } from 'gatsby'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import * as JsCookies from 'js-cookie'
import React, { useEffect, useState } from 'react'

import Cookie from '../../../static/images/cookies/cookie.png'
import useHomepage from '../../hooks/useHomepage'
import { CookiesService } from '../../services'
import './cookies.less'

const Cookies = () => {
  const { t } = useTranslation()
  const { showModal } = useHomepage()
  const language = useI18next().language
  const isCookieConsentRequired = CookiesService.requiresConsent() && JsCookies.get('cookies_accepted') !== 'true'
  CookiesService.initCookies((!isCookieConsentRequired).toString())

  const [displayCookiesDialog, setDisplayCookiesDialog] = useState(isCookieConsentRequired)

  useEffect(() => {
    setDisplayCookiesDialog(isCookieConsentRequired)
  })

  const acceptAll = () => {
    CookiesService.acceptAll()
    setDisplayCookiesDialog(false)
  }

  const showCustomizeSettingsModal = () => {
    showModal({ name: 'cookies' })
  }

  return (
    <>
      {displayCookiesDialog && (
        <div className="cookies__container">
          <div className="legend">
            <div className="image">
              <img src={Cookie} />
            </div>
            <div className="text">
              <span>
                {t('accept-cookies')}
                <Link to={`/${language}/cookie-policy`}>{t('cookie policy')}.</Link>
              </span>
            </div>
          </div>
          <div className="buttons">
            <div className="button accept" onClick={acceptAll}>
              <span>ACCEPT ALL COOKIES</span>
            </div>

            <div className="button customize" onClick={showCustomizeSettingsModal}>
              <span>CUSTOMIZE SETTINGS</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Cookies
