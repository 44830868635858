import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import Landing from '../components/lunchny/Container'

const LunchNy = () => {
  return (
    <Layout>
      <Router>
        <Landing path="/lunchny" />
      </Router>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LunchNy
