import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import * as rdd from 'react-device-detect'
import { FiAlertCircle } from 'react-icons/fi'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

import { LenientValidatePhoneNumber, ValidatePostCode, ValidateUSPhoneNumber, ValidateZip } from '../../helpers'
import useHomepage from '../../hooks/useHomepage'
import CountryRequirement from '../../models/CountryRequirement'
import Invite from '../../models/Invite'
import UtmContent from '../../models/UtmContent'
import { AnalyticsService, ApiService, UrlService } from '../../services'
import InlineSpinner from '../common/InlineSpinner'
import './form.less'

type Props = {
  action?: string
  customClass?: string
  position: string
  activeCityName: string
}

type State = {
  phoneNumber: string
  countryCode: string
  dialCode: string
  phoneNumberValid: boolean
  zip: string
  postCode: string
  zipValid: boolean
  invite: Invite | null
  loading: boolean
  actionName: string
  emailMarketingConsent: boolean
}

const PhoneForm: React.FC<Props> = ({ action, customClass, position, activeCityName }) => {
  const [state, setState] = useState<State>({
    invite: null,
    phoneNumber: '',
    countryCode: '',
    dialCode: '',
    phoneNumberValid: true,
    zip: '',
    postCode: '',
    zipValid: true,
    loading: false,
    actionName: action || 'Browse Restaurants',
    emailMarketingConsent: false,
  })

  const [url, setUrl] = useState('')
  const utmContent = new UtmContent({ position: position }).toString()
  useEffect(() => {
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])
  const { countryCodeIso3 } = useHomepage()
  const { t } = useTranslation()
  const country = new CountryRequirement({ countryCodeIso3 })
  const isZipRequired = country.isZipRequired()

  const platform = () => {
    let device
    if (rdd.isIOS) {
      device = 'ios'
    } else if (rdd.isBrowser) {
      device = 'web'
    } else if (rdd.isAndroid) {
      device = 'android'
    } else {
      rdd.deviceType
    }

    return `${device}_${rdd.browserName
      .toLowerCase()
      .replace(/mobile/i, '')
      .trim()}`
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return

    const phoneNumberValid = isPhoneNumberValid()
    setState({ ...state, phoneNumberValid })

    const zipValid = isZipValid()
    if (phoneNumberValid && zipValid) {
      setState({ ...state, phoneNumberValid, zipValid, loading: true })

      AnalyticsService.trackEvent({
        action: 'Click',
        category: 'Homepage',
        label: state.actionName,
      })

      window.ttq.track('SubmitForm', {
        content_id: 'homepage_lunchny',
      })

      const parsedPhoneNumber: any = parsePhoneNumber(state.phoneNumber)
      ApiService.createPhoneNumberLead(
        {
          phone_number: parsedPhoneNumber.nationalNumber,
          zip: state.zip,
          marketing_consent: true,
          utm_content: utmContent,
          country_code: parsedPhoneNumber.countryCallingCode,
        },
        {
          platform: platform(),
          location: 'homepage_lunchny',
        },
      ).finally(() => {
        window.location.href = url
      })
    } else {
      setState({ ...state, phoneNumberValid, zipValid, loading: false })
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    setState({ ...state, [target.name]: value })
  }

  const isZipValid = () => {
    return isZipRequired ? ValidateZip(state.zip) : ValidatePostCode(state.postCode)
  }

  const isPhoneNumberValid = () => {
    const parsedPhoneNumber = parsePhoneNumber(state.phoneNumber)
    let phoneNumberValid = false

    if (parsedPhoneNumber) {
      const dialCode = parsedPhoneNumber.countryCallingCode.toString()

      phoneNumberValid =
        dialCode === '1'
          ? ValidateUSPhoneNumber(state.phoneNumber)
          : LenientValidatePhoneNumber(state.phoneNumber, dialCode)
    }

    return phoneNumberValid
  }

  const onPhoneChangeHandler = (v: any) => {
    const phone = v
    setState({ ...state, ['phoneNumber']: phone })
  }

  const phoneNumberElement = () => {
    const flag_class = !state.phoneNumberValid ? 'form__error-message form__input__error' : ''

    return (
      <PhoneInput
        countrySelectProps={{ className: 'flag-dropdown' }}
        defaultCountry="US"
        value={state.phoneNumber}
        onChange={onPhoneChangeHandler}
        placeholder={t('Phone Number')}
        className={`form__input ${flag_class}`}
      />
    )
  }

  const zipElement = () => {
    const defaultProps = {
      className: `form__input form__input--zip ${!state.zipValid && 'form__input--error'}`,
      type: 'text',
      onChange: onChangeHandler,
    }

    if (isZipRequired) {
      return <input {...defaultProps} name="zip" value={state.zip} placeholder={t('Zip Code')} />
    } else {
      return <input {...defaultProps} name="postCode" value={state.postCode} placeholder={t('Post Code')} />
    }
  }

  return (
    <>
      <form className={`form ${customClass}`} onSubmit={onSubmitHandler}>
        {phoneNumberElement()}
        {!state.phoneNumberValid && (
          <div className="form__error-message">
            <FiAlertCircle /> {t('Invalid phone number')}
          </div>
        )}
        <div className="form__group">
          {zipElement()}
          {!state.zipValid && (
            <div className="form__error-message form__error-message--zip-mobile">
              <FiAlertCircle /> {isZipRequired ? t('Invalid zip code') : t('Invalid post code')}
            </div>
          )}
          <button className="form__action red-cta" type="submit">
            {state.loading ? <InlineSpinner /> : action || t('Browse restaurants')}
          </button>
        </div>
        {!state.zipValid && (
          <div className="form__error-message form__error-message--zip-desktop">
            <FiAlertCircle /> {isZipRequired ? t('Invalid zip code') : t('Invalid post code')}
          </div>
        )}
      </form>
      <p className="form__message-terms">
        By signing up via text you agree to receive promotional offers at the phone number provided. Standard message
        rates apply. Unsubscribe at anytime by messaging STOP.
      </p>
    </>
  )
}

export default PhoneForm
