import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { MOBILE_VIEW_BREAKPOINT } from '../../constants'
import useHomepage from '../../hooks/useHomepage'
import UtmContent from '../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../services'
import Form from '../form/Form'
import PhoneForm from '../phone-form/PhoneForm'
import ScrollAnimation from '../scroll-animation/Container'

type Props = {
  isFormRequired: boolean
  phoneForm: boolean
}

const HeroForm: React.FC<Props> = ({ isFormRequired, phoneForm }) => {
  const isMobile = typeof window != 'undefined' && window.innerWidth < MOBILE_VIEW_BREAKPOINT
  const { cities, activeCityName, animationProps, landingPageAlias } = useHomepage()
  const [url, setUrl] = useState('')
  const [showChild, setShowChild] = useState(false)
  const position = landingPageAlias !== '' ? `${landingPageAlias}-lunch-hero` : 'lunch-hero'

  const { t } = useTranslation()
  const language = useI18next().language

  const onClickHandler = () => {
    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Get Started',
    })
  }

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'joinnow' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    return <></>
  }

  return (
    <ScrollAnimation {...animationProps} animateIn={isMobile ? 'fadeInUpForm' : 'defaultAnimation'}>
      <div className={`hero__form hero__form--${language}`}>
        <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
          <div className="hero__title">
            {t('hero-title-line-one')}
            <br />
            {t('hero-title-line-two')}
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeInUp'}>
          <div className="hero__subtitle">{t('hero-subtitle')}</div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeInLeft'}>
          {isFormRequired &&
            (phoneForm ? (
              <PhoneForm customClass="form--hero" position={position} activeCityName={activeCityName} />
            ) : (
              <Form customClass="form--hero" position={position} cities={cities} />
            ))}
          {!isFormRequired && (
            <a href={url} className="hero__action red-cta" onClick={onClickHandler}>
              {t('Get Started')}
            </a>
          )}
        </ScrollAnimation>
      </div>
    </ScrollAnimation>
  )
}

export default HeroForm
