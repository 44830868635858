import React from 'react'
import { RouteProps } from 'react-router-dom'

import Cookies from '../cookies/Container'
import Hero from '../hero/Container'
import Map from '../map/Container'
import Meals from '../meals/Container'
import Plans from '../plans/Container'
import SEO from '../seo'

const LunchNYContainer: React.FC<RouteProps<'/lunchny'>> = () => {
  return (
    <>
      <SEO title="Lunch NY" />
      <Hero isFormRequired={true} phoneForm={true} />
      <Meals isDelivery={false} />
      <Map />
      <Plans />
      <Cookies />
    </>
  )
}

export default LunchNYContainer
